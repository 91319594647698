<template>
  <div>
    <MapPolyline
      v-if="showLuShu"
      :start="startTrailPlace"
      :end="endTrailPlace"
      :trails="trails"
      :landmarkPois="landmarkPois"
    />
    <Map v-show="!showLuShu" ref="mapRef" />
    <!-- @choosedLocation="choosedLocation"
      @drawLine="drawLine" -->
    <div style="padding: 10px;margin-bottom: 15px;">
      <van-row>
        <van-col
          :span="24"
          style="text-align: center;position: relative;top: -50px;"
        >
          <van-image width="100" height="100" :src="completeJPG" />
        </van-col>
      </van-row>
      <van-row>
        <van-col :span="12">
          <van-image
            fit="fill"
            style="height: 50px;"
            :src="hotlineJPG"
            @click="callCsPhone"
        /></van-col>
        <van-col :span="12" style="padding-left: 10px;text-align: right;"
          ><van-image
            fit="fill"
            style="width: 50px;height: 50px;"
            :src="contactJPG"
            @click="callDriverPhone"
          />
          <div style="display: inline-block;padding-left: 10px;">
            <div style="margin-bottom: 5px;color: #12851f;">
              {{ order.driverName }}
            </div>
            <div style="color: #12851f;">
              {{ order.dispatchPlateNo }}
            </div>
          </div></van-col
        >
      </van-row>
    </div>
    <div
      v-if="!evaluateShow"
      style="text-align: center;color: #12851f;font-size: 13px;font-weight: bold;margin: 10px 0px;"
    >
      请对我们的服务进行评价
    </div>
    <van-form v-if="!evaluateShow" @submit="submit">
      <van-field
        v-model="form.appraise"
        name="appraise"
        label="综合评分"
        placeholder="综合评分"
        :rules="[{ required: true, message: '请填写评分' }]"
      >
        <template v-slot:input>
          <van-rate
            v-model="form.appraise"
            :size="25"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
        /></template>
      </van-field>
      <van-field label="猜您想说">
        <template v-slot:input>
          <div
            style="margin-right: 10px;color: #12851f;"
            @click="form.mark += '服务到位 '"
          >
            服务到位
          </div>
          <div
            style="margin-right: 10px;color: #12851f;"
            @click="form.mark += '响应很快 '"
          >
            响应很快
          </div>
        </template>
      </van-field>
      <van-field
        v-model="form.mark"
        rows="2"
        autosize
        type="textarea"
        maxlength="200"
        show-word-limit
        placeholder="请输入您宝贵的意见和建议"
        :rules="[]"
      />
      <div style="margin: 16px;">
        <van-button
          :loading="formLoading"
          loading-text="提交中..."
          block
          type="primary"
          native-type="submit"
          >匿名提交</van-button
        >
      </div>
    </van-form>
    <van-form v-else>
      <van-field
        v-model="form.appraise"
        name="appraise"
        label="综合评分"
        placeholder="综合评分"
      >
        <template v-slot:input>
          <van-rate
            v-model="form.appraise"
            :size="25"
            color="#ffd21e"
            void-icon="star"
            void-color="#eee"
            readonly
        /></template>
      </van-field>
      <van-field
        v-model="form.mark"
        name="mark"
        label="您的意见"
        placeholder="意见"
        rows="3"
        autosize
        type="textarea"
        readonly
      />
    </van-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Map from "@/components/Map";
import MapPolyline from "@/components/MapPolyline";
import { request } from "@/api/service";
import { dictFilter } from "@/utils/dictUtils";
import completeJPG from "@/assets/images/ui/complete.png";
import hotlineJPG from "@/assets/images/ui/hotline.jpg";
import contactJPG from "@/assets/images/ui/contact.jpg";
export default {
  name: "Appraise",
  components: { Map, MapPolyline },
  data() {
    return {
      startTrailPlace: {},
      endTrailPlace: {},
      landmarkPois: [],
      trails: [],
      showLuShu: false,
      formLoading: false,
      contactJPG,
      hotlineJPG,
      completeJPG,
      evaluateShow: false,
      driverId: null,
      appraiseValue: 0,
      id: null,
      detail: null,
      startPlace: null,
      startLng: null,
      startLat: null,
      endPlace: null,
      endLng: null,
      endLat: null,
      distance: null,
      estimateTime: null,
      form: {
        orderId: null,
        appraise: 5,
        mark: null,
      },
      order: {
        id: null,
        driverName: null,
        driverPhone: null,
        chargeMark: null,
        dispatchPlateNo: null,
        driverName: null,
      },
      csPhone: "4000623400",
    };
  },
  created() {
    this.init();
    this.setDict();
  },
  methods: {
    ...mapActions("dict", ["setDict"]),
    // choosedLocation({ lng, lat, addr }) {
    //   this.startPlace = addr;
    //   this.startLng = lng;
    //   this.startLat = lat;
    //   // 规划路线
    //   this.$refs.mapRef.drawLine(
    //     {
    //       lng: this.endLng,
    //       lat: this.endLat,
    //     },
    //     {
    //       lng,
    //       lat,
    //     }
    //   );
    // },
    // drawLine({ time, lenth }) {
    //   this.distance = Math.round(lenth.replace("公里", "")) + "公里";
    //   this.estimateTime = time;
    // },
    async init() {
      const { id } = this.$route.query;
      const detail = await request({
        url: `/acs-rescue/order/feedback/orderInfo`,
        method: "post",
        data: {
          orderId: id,
        },
      });
      this.id = id;
      if (!detail) return;
      this.detail = detail;
      this.driverId = detail.driverId;
      // 通过id查询订单详情，初始化订单及终点
      this.endPlace = detail.rescueAddr;
      this.endLng = detail.rescueLongitude;
      this.endLat = detail.rescueLatitude;
      if (detail.dispatch) {
        this.order = {
          id,
          settleType: dictFilter(detail.settleType, "RSC_SETTLE_TYPE"),
          driverPhone: detail.dispatch ? detail.dispatch.driverMobile : "",
          dispatchPlateNo: detail.dispatch.plateNo,
          driverName: detail.dispatch.driverName,
          charges:
            detail.dispatch.priceType === "1"
              ? (detail.dispatch.price || "0.00") + "元"
              : detail.dispatch.price +
                "元 含" +
                detail.dispatch.mileage +
                "公里，超出后" +
                detail.dispatch.pricingMileage +
                "元/公里",
        };
      }
      this.id = id;
      this.form.orderId = id;
      // 判断是否已评价，已评价则展示评价信息
      if (detail.evaluate) {
        this.evaluateShow = true;
        this.form.appraise = Number(detail.evaluate.score || 0);
        this.form.mark = detail.evaluate.reason;
      }
      this.luShuHandle();
    },
    async luShuHandle() {
      // 查询司机全部轨迹
      const res = await request({
        url: `/acs-rescue/order/driverTrail/all`,
        method: "post",
        data: {
          orderId: this.id,
          driverId: this.driverId,
        },
      });
      if (!res || !res.length) return;
      this.startTrailPlace = {
        lng:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.rescueLongitude
            : this.detail.departureLongitude,
        lat:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.rescueLatitude
            : this.detail.departureLatitude,
        address:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.rescueAddress
            : this.detail.departureAddress,
      };
      this.endTrailPlace = {
        lng:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.destinationLongitude
            : this.detail.rescueLongitude,
        lat:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.destinationLatitude
            : this.detail.rescueLatitude,
        address:
          this.detail.order.projectCode &&
          this.detail.order.projectCode.charAt(0) === "T"
            ? this.detail.destinationAddress
            : this.detail.rescueAddress,
      };
      let trails = res.filter((r) =>
        this.detail.order.projectCode &&
        this.detail.order.projectCode.charAt(0) === "T"
          ? r.type === "1"
          : r.type === "0"
      );
      for (let i = 0; i < trails.length; i++) {
        let r = trails[i];
        this.trails.push({
          id: r.id,
          lat: r.latitude,
          lng: r.longitude,
        });
      }

      // this.landmarkPois = [
      //   {
      //     lng: this.detail.rescueLongitude,
      //     lat: this.detail.rescueLatitude,
      //     html: "救援地",
      //     pauseTime: 1,
      //   },
      // ];
      this.showLuShu = true;
    },
    callCsPhone() {
      window.location.href = "tel:" + this.csPhone;
    },
    callDriverPhone() {
      if (this.order.driverPhone)
        window.location.href = "tel:" + this.order.driverPhone;
    },
    async submit() {
      this.formLoading = true;
      // 调用司机评价接口
      try {
        await request({
          url: `/acs-rescue/order/evaluate/submit`,
          method: "post",
          data: {
            orderId: this.id,
            driverId: this.driverId,
            score: this.form.appraise,
            reason: this.form.mark,
          },
        });
        this.$toast("已评价");
        this.init();
        this.formLoading = false;
      } catch (e) {
        console.log(e);
        this.formLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
