<template>
  <baidu-map
    class="map"
    :center="center"
    :zoom="11"
    :scroll-wheel-zoom="true"
    :ak="baiduMapKey"
    style="width: 100%;height: 300px;"
    @ready="handler"
  >
    <!-- <bm-polyline
      :path="movePath"
      stroke-color="#12851f"
      :stroke-opacity="0.5"
      :stroke-weight="3"
      :editing="false"
    ></bm-polyline>
    <bm-marker
      :position="{ lng: startMark.lng, lat: startMark.lat }"
      :icon="{ url: startIcon, size: { width: 48, height: 48 } }"
    ></bm-marker>
    <bm-marker
      :position="{ lng: endMark.lng, lat: endMark.lat }"
      :icon="{ url: endIcon, size: { width: 48, height: 48 } }"
    ></bm-marker> -->
    <bm-driving
      :start="start"
      :end="end"
      :panel="false"
      :autoViewport="true"
    ></bm-driving>
    <bml-lushu
      @stop="reset"
      :path="movePath"
      :icon="icon"
      :play="play"
      :speed="400"
      :autoView="true"
      :rotation="false"
    >
    </bml-lushu>
    <!-- <div style="position: fixed; top: 20px; left: 20px;">
      <van-button type="primary" @click="handPathPlay">路径回放</van-button>
      <van-button type="primary" @click="handPathStop">暂停回放</van-button>
    </div> -->
  </baidu-map>
</template>

<script>
import { BmDriving, BmlLushu, BaiduMap, BmMarker, BmPolyline } from "vue-baidu-map";
import startIconPng from "@/assets/images/ui/start-icon.png";
import endIconPng from "@/assets/images/ui/end-icon.png";
export default {
  name: "MapPolyline",
  props: {
    start: {
      type: Object,
      default: null,
    },
    end: {
      type: Object,
      default: null,
    },
    landmarkPois: {
      type: Array,
      default: () => [],
    },
    trails: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BmlLushu,
    BaiduMap,
    BmDriving,
    BmMarker,
    BmPolyline,
  },
  computed: {
    baiduMapKey() {
      return process.env.VUE_APP_BAIDU_MAP_KEY;
    },
  },
  created() {
    /*js代码*/
    // 轨迹路径
    this.movePath = this.trails;
    if (this.start) {
      this.movePath.unshift(this.start);
    }
    if (this.end) {
      this.movePath.push(this.end);
    }
    this.$nextTick(() => {
      if (this.movePath.length) {
        //起点
        this.startMark = this.start || this.movePath[0];
        //终点
        this.endMark = this.end || this.movePath[this.movePath.length - 1];
        //设置中心点
        let length = this.movePath.length;
        let middle = -1;
        if (length % 2 === 0) {
          middle = length / 2 + 1;
        } else {
          middle = (length + 1) / 2;
        }
        this.center = this.movePath[middle];
      }
    });
  },
  data() {
    return {
      play: false,
      movePath: [], //轨迹路径数组
      startIcon: startIconPng,
      endIcon: endIconPng,
      startMark: {}, //轨迹起点经纬度坐标
      endMark: {}, //轨迹终点经纬度坐标
      center: {
        lng: "",
        lat: "",
      },
      icon: {
        url: "http://api.map.baidu.com/library/LuShu/1.2/examples/car.png",
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } },
      },
    };
  },
  methods: {
    handler({ BMap, map }) {
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      map.centerAndZoom(new BMap.Point(116.3894, 39.995), 17);
    },
    // 路径回放
    handPathPlay() {
      this.play = true;
    },
    // 暂停回放
    handPathStop() {
      this.play = false;
    },
    reset() {
      this.play = false;
    },
    handleSearchComplete(res) {
      this.path = res
        .getPlan(0)
        .getRoute(0)
        .getPath();
    },
  },
};
</script>
